.homebg {
  background-color: #f1f6f8;
  min-height: 100vh;
}

.increased-top-margin {
  margin-top: 38px; /* 60% increase from typical 4rem (64px) padding */
}

.contact-us-wrapper {
  margin-bottom: 6rem; /* Assuming the original margin was 2rem, we're tripling it */
}

/* Add any other styles for the home page here */
