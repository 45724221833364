.customer-experience-section {
  padding: 4rem 0;
  background-color: #ffffff;
}

.customer-experience-header {
  text-align: left;
  margin-bottom: 3rem;
  padding-left: 70px;
}

.customer-experience-heading {
  color: #333;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  word-wrap: break-word;
  max-width: 800px;
}

.customer-experience-subheading {
  font-size: 1.2rem;
  color: #666;
  line-height: 1.4;
  word-wrap: break-word;
  max-width: 800px;
}

.customer-experience-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 150px;
}

.cta-button {
  font-size: 1.1rem;
  padding: 0.75rem 1.5rem;
  margin-bottom: 2rem;
  display: inline-block;
}

.customer-experience-content .row {
  width: 100%;
}

.customer-experience-content .row > div:first-child {
  margin-top: 50px;
  margin-left: -180px;
  width: calc(50% + 180px - 170px);
}

.customer-experience-content .row > div:last-child {
  margin-left: 70px;
  width: calc(50% - 70px + 120px);
}

.conversation-card {
  background-color: #ffffff;
  border: none;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  height: 550px;
  position: relative;
  overflow: hidden;
  animation: float 6s ease-in-out infinite;
  width: calc(100% - 170px);
}

.audio-conversation-animation {
  padding: 1.5rem;
  transition: opacity 0.5s ease-out;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.audio-conversation-animation.fade-out {
  opacity: 0;
  transition: opacity 1s ease-out;
}

.conversation-bubble {
  padding: 1rem;
  border-radius: 20px;
  margin-bottom: 1rem;
  opacity: 0;
  max-width: 80%;
  word-wrap: break-word;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  animation: bubbleAppear 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
}

.conversation-bubble .icon {
  margin-right: 0.5rem;
  font-size: 1.5rem !important;
  flex-shrink: 0;
  animation: iconPop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  animation-delay: 0.2s;
  opacity: 0;
  transform: scale(0.5);
}

.conversation-bubble.ai {
  background-color: #e1f5fe;
  color: #01579b;
  margin-right: auto;
  border-bottom-left-radius: 5px;
  transform-origin: left center;
}

.conversation-bubble.user {
  background-color: #e8f5e9;
  color: #1b5e20;
  margin-left: auto;
  text-align: right;
  border-bottom-right-radius: 5px;
  flex-direction: row-reverse;
  transform-origin: right center;
}

.conversation-bubble.user .icon {
  margin-right: 0;
  margin-left: 0.5rem;
}

.empty-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  opacity: 0;
  animation: cardTransition 3s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
}

.empty-card .green-tick {
  font-size: 5rem;
  color: #4caf50;
  margin-bottom: 1rem;
  animation: tickAppear 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  animation-delay: 0.5s;
  opacity: 0;
  transform: scale(0.5);
}

.empty-card p {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  text-align: center;
  animation: textFadeIn 0.5s ease-out forwards;
  animation-delay: 0.8s;
  opacity: 0;
}

.right-side-content {
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.customer-experience-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.customer-experience-item .customer-experience-icon {
  font-size: 2rem;
  color: #0056b3;
  margin-right: 1rem;
  flex-shrink: 0;
}

.customer-experience-item h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.customer-experience-item p {
  font-size: 1rem;
  color: #666;
  line-height: 1.4;
}

@keyframes bubbleAppear {
  0% {
    opacity: 0;
    transform: scale(0.8) translateY(20px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

@keyframes iconPop {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes cardTransition {
  0%, 100% { opacity: 0; transform: scale(0.9); }
  10%, 90% { opacity: 1; transform: scale(1); }
}

@keyframes tickAppear {
  0% {
    opacity: 0;
    transform: scale(0.5) rotate(-45deg);
  }
  100% {
    opacity: 1;
    transform: scale(1) rotate(0deg);
  }
}

@keyframes textFadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
}

.conversation-bubble:nth-child(1) { animation-delay: 0.2s; }
.conversation-bubble:nth-child(2) { animation-delay: 0.8s; }
.conversation-bubble:nth-child(3) { animation-delay: 1.4s; }

@media (max-width: 992px) {
  .customer-experience-header,
  .customer-experience-content {
    padding-left: 20px;
    padding-right: 20px;
  }

  .customer-experience-content .row > div:first-child {
    margin-left: 0;
    width: 100%;
  }

  .customer-experience-content .row > div:last-child {
    margin-left: 0;
    width: 100%;
  }

  .conversation-card {
    height: auto;
    min-height: 600px;
    width: 100%;
    margin-bottom: 2rem;
  }

  .right-side-content {
    padding: 1.5rem;
  }

  .customer-experience-item h3 {
    font-size: 1.3rem;
  }

  .customer-experience-item p {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .customer-experience-section {
    padding: 2rem 0;
  }

  .customer-experience-heading {
    font-size: 2rem;
  }

  .customer-experience-subheading {
    font-size: 1rem;
  }

  .cta-button {
    font-size: 1rem;
    padding: 0.5rem 1rem;
  }

  .conversation-card {
    min-height: 550px;
  }

  .right-side-content {
    padding: 1rem;
  }

  .customer-experience-item {
    margin-bottom: 1.5rem;
  }

  .customer-experience-item .customer-experience-icon {
    font-size: 1.5rem;
  }

  .customer-experience-item h3 {
    font-size: 1.2rem;
  }

  .customer-experience-item p {
    font-size: 0.9rem;
  }
}
