/* BlogListPage styles */
.blog-list-container {
  min-height: 100vh;
  background-color: #f9fafb;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.blog-list-content {
  flex-grow: 1;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.blog-list-title {
  font-size: 1.75rem;
  font-weight: bold;
  color: #1e40af;
  margin-bottom: 1rem;
  user-select: none;
  pointer-events: none;
}

.featured-blog-link {
  text-decoration: none;
  user-select: none;
  display: block;
  margin-bottom: 2rem;
}

.featured-blog-container {
  position: relative;
  width: 100%;
  height: 10rem;
  background-color: #dbeafe;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  transition: background-color 0.3s ease-in-out;
}

.featured-blog-container:hover {
  background-color: #bfdbfe;
}

.featured-blog-content {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  color: #1e40af;
  border-radius: 0.5rem;
}

.featured-blog-title {
  font-size: 1.25rem;
  font-weight: bold;
  text-decoration: none;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  user-select: none;
}

.featured-blog-meta {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.featured-blog-date {
  font-size: 0.75rem;
  text-decoration: none;
  user-select: none;
}

.featured-blog-read-more {
  font-size: 0.75rem;
  font-weight: 600;
  text-decoration: none;
  user-select: none;
}

.latest-blogs-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #1e40af;
  margin-bottom: 1.5rem;
  text-align: center;
  user-select: none;
  pointer-events: none;
}

@media (min-width: 640px) {
  .blog-list-container {
    padding: 2rem;
  }

  .blog-list-title {
    font-size: 2rem;
  }

  .featured-blog-container {
    height: 12rem;
  }

  .featured-blog-content {
    padding: 1.5rem;
  }

  .featured-blog-title {
    font-size: 1.5rem;
  }

  .featured-blog-date,
  .featured-blog-read-more {
    font-size: 0.875rem;
  }

  .latest-blogs-title {
    font-size: 1.875rem;
  }
}

@media (min-width: 1024px) {
  .blog-list-title {
    font-size: 2.25rem;
  }

  .featured-blog-container {
    height: 14rem;
  }

  .featured-blog-title {
    font-size: 1.75rem;
  }

  .latest-blogs-title {
    font-size: 2.25rem;
  }
}
