.glossary-section {
  padding: 4rem 0;
  background-color: #f8f9fa;
}

.glossary-section h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 2rem;
}

.audio-card {
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  transition: all 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.audio-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.audio-info {
  margin-bottom: 1rem;
}

.audio-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.5rem;
}

.audio-description {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 1rem;
}

.audio-controls {
  display: flex;
  align-items: center;
  margin-top: auto;
}

.play-button {
  background-color: #4353FF;
  color: white;
  border: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-right: 1rem;
  flex-shrink: 0;
}


.play-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.waveform-container {
  flex-grow: 1;
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 0.5rem;
}

.waveform {
  height: 60px;
}

@media (max-width: 768px) {
  .glossary-section h2 {
    font-size: 2rem;
  }

  .audio-card {
    padding: 1rem;
  }

  .audio-title {
    font-size: 1.1rem;
  }

  .audio-description {
    font-size: 0.8rem;
  }

  .play-button {
    width: 40px;
    height: 40px;
  }

  .waveform {
    height: 50px;
  }
}

@media (max-width: 576px) {
  .audio-controls {
    flex-direction: column;
    align-items: flex-start;
  }

  .play-button {
    margin-bottom: 1rem;
  }

  .waveform-container {
    width: 100%;
  }
}
