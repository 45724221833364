@import url('https://fonts.googleapis.com/css2?family=Dosis&family=Gloria+Hallelujah&family=Inter:wght@300&family=Nothing+You+Could+Do&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Mono&family=Shadows+Into+Light&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
    font-family: "Poppins", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient( #0a369d 3.68%,  #4472ca  52%, #5e7ce2 97.38%);
  --gradient-bar: linear-gradient(103.22deg, #0a369d 3.68%,  #4472ca  52%, #5e7ce2 97.38%);
  
  --color-bg: #040C18;
  --color-footer : #25113a;
  --color-blog: #390454;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;
}



/* General List Styling */
ol, ul {
  margin-left: 20px;
  padding-left: 20px;
  list-style-position: inside; /* Ensure bullet or number is inside the block */
}

/* Handle Unordered List Styles for Custom Bullet Points */
ul {
  list-style-type: disc ; /* Default bullet point */ 
}

ul ul {
  list-style-type: circle; /* Nested unordered list with hollow circle */
}

ul ul ul {
  list-style-type: square; /* Nested unordered list with square */
}

/* Adjusting Spacing and Line Height for Readability */
p, ul, ol {
  line-height: 1.6; /* Adjust the line height */
  margin-bottom: 15px; /* Add spacing between paragraphs and lists */
}

ul li{
  line-height: 1.6; /* Adjust the line height */
  margin-bottom: 15px; /* Add spacing between paragraphs and lists */
}

li {
  margin-bottom: 10px; /* Add spacing between list items */
  font-size: inherit; /* Ensure it inherits the font size */
  color: inherit; /* Inherit text color */
}

ul[style*="list-style-type:●"] li {
  list-style-type: disc; /* Solid circle */
}

ul[style*="list-style-type:○"] li {
  list-style-type: circle; /* Hollow circle */
}

ul[style*="list-style-type:■"] li {
  list-style-type: square; /* Solid square */
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
}


