.policybg{
          /* background: linear-gradient(195deg, #170936 15%, #472a87 55%); */
            background-image: linear-gradient(to bottom right, #170936, #472a87, #170936);

   color: white
}
.subdiv{
    /* margin-top: 1rem;
    margin-bottom: 1rem; */
}
.policybox{
    box-shadow: rgba(188, 188, 188, 0.781) 0px 7px 29px 0px;

}