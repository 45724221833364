.terms-of-service {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  padding-top: 80px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
}

.terms-header {
  text-align: center;
  margin-bottom: 2rem;
}

.terms-header h1 {
  font-size: 2.5rem;
  color: #4472ca;
  margin-bottom: 0.5rem;
}

.terms-header p {
  font-size: 1rem;
  color: #666;
}

.terms-content {
  background-color: #f9f9f9;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.terms-intro {
  margin-bottom: 2rem;
}

.terms-section {
  margin-bottom: 2rem;
}

.terms-section h2 {
  font-size: 1.5rem;
  color: #4472ca;
  margin-bottom: 1rem;
}

.terms-section p, .terms-section ul {
  margin-bottom: 1rem;
}

.terms-section ul {
  padding-left: 1.5rem;
}

.terms-section li {
  margin-bottom: 0.5rem;
}

a {
  color: #4472ca;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.cta-section {
  background-color: #4472ca;
  padding: 2rem;
  border-radius: 8px;
  margin-top: 2rem;
  text-align: center;
}

.cta-section h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color:white
}

.cta-section p {
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
  color:white
}

.cta-button {
  display: inline-block;
  background-color: white;
  color: #4472ca;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-weight: bold;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

.cta-button:hover {
  background-color: #e0e0e0;
  color: #4472ca;
  text-decoration: none;
}

@media (max-width: 768px) {
  .terms-of-service {
    padding: 1rem;
    padding-top: 80px;
  }

  .terms-header h1 {
    font-size: 2rem;
  }

  .terms-content {
    padding: 1rem;
  }

  .cta-section {
    padding: 1.5rem;
  }

  .cta-section h2 {
    font-size: 1.5rem;
  }
}