.talk-with-ai-section {
  background-color: #f0f8ff;
}

.talk-with-ai-header {
  text-align: left;
  margin-bottom: 3rem;
  padding-left: 70px;
}

.talk-with-ai-heading {
  color: #0056b3;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  word-wrap: break-word;
  max-width: 800px;
}

.talk-with-ai-subheading {
  font-size: 1.2rem;
  color: #444;
  line-height: 1.4;
  word-wrap: break-word;
  max-width: 800px;
}

.talk-with-ai-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 70px;
  padding-right: 70px;
}

.cta-button {
  font-size: 1.1rem;
  padding: 0.75rem 1.5rem;
  margin-bottom: 2rem;
  display: inline-block;
  background-color: #0056b3;
  border-color: #0056b3;
  color: #ffffff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #004494;
}

.talk-with-ai-content .row > div:last-child {
  margin-top: -60px;
}

.conversation-card {
  background-color: #ffffff;
  border: none;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  margin-top: -30px;
  width: 110%;
  height: 400px;
  position: relative;
  overflow: hidden;
}

.conversation-card .card-body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0;
}

.wave-animation {
  position: absolute;
  top: 25%;
  left: 25%;
  width: 50%;
  height: 50%;
  overflow: hidden;
  border-radius: 50%;
}

.wave {
  position: absolute;
  width: 200%;
  height: 200%;
  background: linear-gradient(to bottom, rgba(0, 86, 179, 0.3), rgba(0, 86, 179, 0.1));
  border-radius: 40%;
}

.wave:nth-child(1) {
  animation: wave1 8s infinite linear;
}

.wave:nth-child(2) {
  animation: wave2 10s infinite linear;
  opacity: 0.7;
}

.wave:nth-child(3) {
  animation: wave3 12s infinite linear;
  opacity: 0.5;
}

.wave:nth-child(4) {
  animation: wave4 14s infinite linear;
  opacity: 0.3;
}

@keyframes wave1 {
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
}

@keyframes wave2 {
  0% { transform: translate(-50%, -50%) rotate(0deg) scale(0.9); }
  100% { transform: translate(-50%, -50%) rotate(-360deg) scale(0.9); }
}

@keyframes wave3 {
  0% { transform: translate(-50%, -50%) rotate(0deg) scale(0.8); }
  100% { transform: translate(-50%, -50%) rotate(360deg) scale(0.8); }
}

@keyframes wave4 {
  0% { transform: translate(-50%, -50%) rotate(0deg) scale(0.7); }
  100% { transform: translate(-50%, -50%) rotate(-360deg) scale(0.7); }
}

.audio-player-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.play-button {
  font-size: 3rem;
  background: none;
  border: none;
  color: #0056b3;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.play-button:hover {
  transform: scale(1.1);
}

.audio-progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  background-color: #0056b3;
  transition: width 0.1s linear;
}

.talk-with-ai-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.talk-with-ai-item .talk-with-ai-icon {
  font-size: 2rem;
  color: #0056b3;
  margin-right: 1rem;
  flex-shrink: 0;
}

.talk-with-ai-item h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.talk-with-ai-item p {
  font-size: 1rem;
  color: #666;
  line-height: 1.4;
}

@media (max-width: 992px) {
  .talk-with-ai-header,
  .talk-with-ai-content {
    padding-left: 40px;
    padding-right: 40px;
  }

  .conversation-card {
    height: 350px;
  }

  .talk-with-ai-item h3 {
    font-size: 1.3rem;
  }

  .talk-with-ai-item p {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .talk-with-ai-section {
    padding: 3rem 0;
  }

  .talk-with-ai-header,
  .talk-with-ai-content {
    padding-left: 20px;
    padding-right: 20px;
  }

  .talk-with-ai-heading {
    font-size: 2rem;
  }

  .talk-with-ai-subheading {
    font-size: 1rem;
  }

  .cta-button {
    font-size: 1rem;
    padding: 0.5rem 1rem;
  }

  .talk-with-ai-content .row > div:last-child {
    margin-top: 0;
  }

  .conversation-card {
    height: 300px;
    margin-bottom: 2rem;
    width: 100%;
    margin-top: 0;
  }

  .talk-with-ai-item {
    margin-bottom: 1.5rem;
  }

  .talk-with-ai-item .talk-with-ai-icon {
    font-size: 1.5rem;
  }

  .talk-with-ai-item h3 {
    font-size: 1.2rem;
  }

  .talk-with-ai-item p {
    font-size: 0.9rem;
  }

  .play-button {
    font-size: 2.5rem;
    width: 50px;
    height: 50px;
  }
}
