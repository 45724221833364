
.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 10rem); /* Adjust for both header and footer height */
    padding: 5rem 1rem; /* Add padding top and bottom */
}

.login-page {
    width: 100%;
    max-width: 31.25rem;
    padding: 2rem;
    border: 1px solid #e0e0e0;
    border-radius: 0.75rem;
    background: white;
    box-shadow: 0 0.375rem 0.5rem rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-page:hover {
    box-shadow: 0 0.625rem 0.9375rem rgba(0, 0, 0, 0.15);
}

.login-heading {
    font-size: 2rem;
    margin-bottom: 1rem;
    text-align: center;
}

.detail {
    font-size: 1rem;
    margin-bottom: 1.5rem;
    text-align: center;
}

.form-group {
    width: 100%;
    margin-bottom: 1.25rem;
}

.form-control {
    font-size: 1rem;
    padding: 0.75rem;
    width: 100%;
    border: 1px solid #e0e0e0;
    border-radius: 0.25rem;
}

.name-fields {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1.25rem;
}

.name-fields .form-group {
    flex: 1 1 calc(50% - 0.5rem);
    min-width: 150px;
    margin-bottom: 0;
}

.submitbtn {
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
    width: 100%;
    margin-top: 1rem;
    border-radius: 2rem;
    background-color: #2a65d3;
    color: #ffffff;
    border: none;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.2s ease;
    cursor: pointer;
}

.submitbtn:hover {
    background-color: #276bd1;
    transform: translateY(-2px);
}

.submitbtn:active {
    transform: translateY(0);
}

.bottom-tag {
    font-size: 0.9rem;
    margin-top: 1.5rem;
    text-align: center;
}

.row {
    width: 100%;
}

@media screen and (min-width: 768px) {
    .skip-btn {
        position: absolute;
        max-width: 200px;
        right: -210px;
    }
    .skip-sign {
        position: relative;
    }
}

@media screen and (max-width: 480px) {
    .login-page {
        padding: 1.5rem;
    }

    .login-heading {
        font-size: 1.75rem;
    }

    .detail {
        font-size: 0.9rem;
    }

    .form-control,
    .submitbtn {
        font-size: 0.9rem;
    }

    .name-fields {
        flex-direction: column;
    }

    .name-fields .form-group {
        flex: 1 1 100%;
    }
}
