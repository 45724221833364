.about-page {
  padding-top: 70px;
}

.hero-section {
  background-color: #f8f9fa;
  padding: 20px 0;
  text-align: center;
  margin-bottom: 40px;
}

.hero-section h1 {
  font-size: 3rem;
  font-weight: bold;
  color: #333;
}

.who-we-are,
.our-vision,
.our-journey,
.learn-more {
  margin-bottom: 60px;
}

h2 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.button-group .btn {
  font-size: 1.2rem;
  padding: 12px 24px;
  border-radius: 50px;
  text-decoration: none;
}

.button-group .btn:hover {
  text-decoration: none;
}

.vision-image,
.journey-image {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 400px;
  object-fit: cover;
  width: 100%;
}

.learn-more {
  text-align: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.learn-more h2 {
  margin-bottom: 20px;
}

.learn-more p {
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .hero-section h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2rem;
  }

  .button-group {
    flex-direction: column;
    align-items: center;
  }

  .button-group .btn {
    width: 100%;
    max-width: 300px;
    margin-bottom: 15px;
  }

  .vision-image,
  .journey-image {
    margin-bottom: 20px;
  }
}
