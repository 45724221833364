/* Pagination styles */
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-button {
  padding: 0.5rem 1rem;
  margin: 0 0.25rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background-color: #ffffff;
  color: #4a5568;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
}

.pagination-button:hover {
  background-color: #edf2f7;
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-button.active {
  background-color: #4299e1;
  color: #ffffff;
  border-color: #4299e1;
}

.pagination-ellipsis {
  margin: 0 0.25rem;
  color: #4a5568;
}
