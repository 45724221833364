@import url("https://fonts.googleapis.com/css?family=Exo:400,700");

.partners-heading {
    font-family: "Exo", sans-serif;
    color: #ffffff;
    margin: 20px 0;
    font-weight: bold;
    text-align: center;
}

@keyframes slide {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-50%);
    }
}

.supportbg {
    background: #4472ca;
    overflow: hidden;
    padding: 40px 0;
}

.logos {
    position: relative;
}

.logos-slide-container {
    display: flex;
    width: 200%;
    overflow: hidden;
}

.logos-slide {
    display: flex;
    animation: 20s slide infinite linear;
    width: 100%;
    justify-content: space-around;
}

.logos:hover .logos-slide {
    animation-play-state: paused;
}

.logo-img {
    height: 120px;
    width: 240px;
    padding: 20px 50px;  /* Increased horizontal padding to 50px (2X the original) */
    object-fit: contain;
}

@media (max-width: 768px) {
    .logo-img {
        height: 80px;
        width: 160px;
        padding: 10px 25px;  /* Increased horizontal padding to 25px (2X the original) for mobile */
    }
}
