.safe-and-secure-section {
  padding: 4rem 0;
  background-color: #f0f8ff;
}

.safe-and-secure-header {
  text-align: left;
  margin-bottom: 3rem;
  padding-left: 70px;
}

.safe-and-secure-heading {
  color: #003366;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  word-wrap: break-word;
  max-width: 800px;
}

.safe-and-secure-subheading {
  font-size: 1.2rem;
  color: #4a4a4a;
  line-height: 1.4;
  word-wrap: break-word;
  max-width: 800px;
}

.safe-and-secure-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 70px;
  padding-right: 70px;
}

.cta-button {
  font-size: 1.1rem;
  padding: 0.75rem 1.5rem;
  margin-bottom: 2rem;
  display: inline-block;
  background-color: #006400;
  border-color: #006400;
}

.conversation-card {
  background-color: #ffffff;
  border: none;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  margin-top: -50px;
  margin-left: 90px;
  width: calc(100% - 90px + 60px);
  height: 100%;
  min-height: 550px;
  position: relative;
  overflow: hidden;
}

.conversation-card .card-body {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.left-side-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.safe-and-secure-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.safe-and-secure-item .safe-and-secure-icon {
  font-size: 2rem;
  color: #0056b3;
  margin-right: 1rem;
  flex-shrink: 0;
}

.safe-and-secure-item h3 {
  font-size: 1.5rem;
  color: #003366;
  margin-bottom: 0.5rem;
}

.safe-and-secure-item p {
  font-size: 1rem;
  color: #4a4a4a;
  line-height: 1.4;
}

@media (max-width: 992px) {
  .safe-and-secure-header,
  .safe-and-secure-content {
    padding-left: 40px;
    padding-right: 40px;
  }

  .conversation-card {
    margin-left: 45px;
    width: calc(100% - 45px + 30px);
  }

  .safe-and-secure-item h3 {
    font-size: 1.3rem;
  }

  .safe-and-secure-item p {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .safe-and-secure-section {
    padding: 3rem 0;
  }

  .safe-and-secure-header,
  .safe-and-secure-content {
    padding-left: 20px;
    padding-right: 20px;
  }

  .safe-and-secure-heading {
    font-size: 2rem;
  }

  .safe-and-secure-subheading {
    font-size: 1rem;
  }

  .cta-button {
    font-size: 1rem;
    padding: 0.5rem 1rem;
  }

  .conversation-card {
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 0;
    width: 100%;
    min-height: 300px;
  }

  .safe-and-secure-item {
    margin-bottom: 1.5rem;
  }

  .safe-and-secure-item .safe-and-secure-icon {
    font-size: 1.5rem;
  }

  .safe-and-secure-item h3 {
    font-size: 1.2rem;
  }

  .safe-and-secure-item p {
    font-size: 0.9rem;
  }
}
