/* Styles for SignupTwo component */
.signup-two-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 160px); /* Adjust for both header and footer height */
  padding: 80px 0; /* Add padding top and bottom */
}

.signup-two-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100% !important;
  max-width: 500px !important;
  padding: 3rem;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  background: white;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.signup-two-form:hover {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
}

.signup-two-heading {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  text-align: center;
}

.signup-two-detail {
  font-size: 1.1rem;
  margin-bottom: 2rem;
  text-align: center;
}

.form-group {
  width: 100%;
  margin-bottom: 1.5rem;
}

.form-control {
  font-size: 1.1rem;
  padding: 0.75rem;
  width: 100%;
}

.otp-input-container {
  display: flex;
  justify-content: space-between;
}

.otp-input {
  width: 40px;
  text-align: center;
}

.timer-container {
  width: 100%;
  margin-bottom: 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.resend-button {
  opacity: 1;
  cursor: pointer;
}

.resend-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.signup-two-submit-btn {
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 2rem;
  background-color: #2a65d3;
  color: #ffffff;
  border: none;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
  cursor: pointer;
}

.signup-two-submit-btn:hover {
  background-color: #276bd1;
  transform: translateY(-2px);
}

.signup-two-submit-btn:active {
  transform: translateY(0);
}

.signup-two-login-link {
  text-decoration: none;
}

.bottom-tag {
  font-size: 1.1rem;
  margin-top: 1.5rem;
  text-align: center;
}

/* Styles for password toggle icon */
.input-group {
  position: relative;
}

.input-group-text {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  z-index: 10;
}

.input-group-text:hover {
  color: #2a65d3;
}

.input-group-text svg {
  vertical-align: middle;
}

/* Adjust the confirm password input to accommodate the icon */
#formConfirmPassword .form-control {
  padding-right: 40px; /* Make room for the icon */
}

/* Ensure the input and icon are treated as a single unit when focused */
#formConfirmPassword .form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  border-color: #80bdff;
}

#formConfirmPassword .form-control:focus + .input-group-text {
  color: #2a65d3;
}
