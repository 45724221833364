.sitemap-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  padding-top: 80px;
  font-family: Arial, sans-serif;
}

.sitemap-container h1 {
  color: #4472ca;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.5rem;
}

.sitemap-vertical {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.sitemap-section {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sitemap-section h2 {
  color: #4472ca;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  border-bottom: 2px solid #4472ca;
  padding-bottom: 0.5rem;
}

.sitemap-section ul {
  list-style-type: none;
  padding-left: 0;
  columns: 2;
  column-gap: 2rem;
}

.sitemap-section li {
  margin-bottom: 0.75rem;
  break-inside: avoid-column;
}

.sitemap-section a {
  color: #333;
  text-decoration: none;
  transition: color 0.3s ease;
}

.sitemap-section a:hover {
  color: #4472ca;
  text-decoration: underline;
}

@media (max-width: 600px) {
  .sitemap-section ul {
    columns: 1;
  }
}
