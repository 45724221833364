/* Styles for Login component */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 10rem); /* Adjust for both header and footer height */
  padding: 8rem 1rem; /* Add padding top and bottom */
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 31.25rem;
  padding: 2rem;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 0.75rem;
  box-shadow: 0 0.375rem 0.5rem rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.login-form:hover {
  box-shadow: 0 0.625rem 0.9375rem rgba(0, 0, 0, 0.15);
}

.login-heading {
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 2rem;
}

.login-input {
  width: 100%;
  margin-bottom: 1.25rem;
}

.login-input input {
  width: 100%;
  font-size: 1rem;
  padding: 0.75rem;
  border: 1px solid #e0e0e0;
  border-radius: 0.25rem;
}

.login-submit-btn {
  width: 100%;
  margin-bottom: 1.5rem;
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
  border-radius: 2rem;
  background-color: #2a65d3;
  color: #ffffff;
  border: none;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
  cursor: pointer;
}

.login-submit-btn:hover {
  background-color: #276bd1;
  transform: translateY(-2px);
}

.login-submit-btn:active {
  transform: translateY(0);
}

.login-forgot-password {
  text-align: right;
  width: 100%;
  margin-bottom: 1.5rem;
  font-size: 0.9rem;
}

.login-signup-link {
  text-align: center;
  width: 100%;
  font-size: 0.9rem;
}

.login-remember-forgot {
  font-size: 0.9rem;
}

@media (max-width: 480px) {
  .login-form {
    padding: 1.5rem;
  }

  .login-heading {
    font-size: 1.75rem;
  }

  .login-input input,
  .login-submit-btn {
    font-size: 0.9rem;
  }
}

/* Remove underline from links on hover */
.login-form a {
  text-decoration: none;
}

.login-form a:hover {
  text-decoration: none;
}

.login-forgot-password a:hover,
.login-signup-link a:hover {
  text-decoration: none;
}

/* Styles for password toggle icon */
.login-input .input-group {
  position: relative;
}

.login-input .input-group-text {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  cursor: pointer;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
}

.login-input .input-group-text:hover {
  color: #2a65d3;
}

.login-input .form-control {
  padding-right: 40px; /* Make room for the icon */
}

.login-input .form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  border-color: #80bdff;
}

.login-input .form-control:focus + .input-group-text {
  color: #2a65d3;
}
