.career-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  padding-top: 80px;
}

.career-header {
  text-align: center;
  margin-bottom: 60px;
}

.career-header h1 {
  font-size: 3rem;
  color: black;
  font-weight: bold;
  margin-bottom: 20px;
}

.career-header p {
  font-size: 1.2rem;
  color: #333;
}

.company-culture, .benefits {
  margin-bottom: 60px;
  color: black;
}

.company-culture h2, .benefits h2 {
  font-size: 2.5rem;
  color: black;
  margin-bottom: 30px;
  text-align: center;
}

.culture-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
}

.culture-item {
  background-color: #f8f9fa;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.culture-item:hover {
  transform: translateY(-5px);
}

.culture-item h3 {
  font-size: 1.5rem;
  color: black;
  margin-bottom: 15px;
}

.culture-item p {
  font-size: 1rem;
  color: #555;
}

.benefits-list {
  list-style-type: none;
  padding: 0;
}

.benefits-list li {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 15px;
  padding-left: 30px;
  position: relative;
}

.benefits-list li::before {
  content: '✓';
  color: black;
  font-weight:bold;
  position: absolute;
  left: 0;
}

.career-cta {
  text-align: center;
  background-color: #0A369D;
  color: white;
  padding: 60px 20px;
  border-radius: 10px;
}

.career-cta h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: white; /* Ensure heading is white */
}

.career-cta p {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: white; /* Ensure paragraph text is white */
}

.email-contact {
  font-size: 1.3rem;
  margin-top: 20px;
}

.email-contact a {
  color: #ffffff;
  text-decoration: underline;
  transition: opacity 0.3s ease;
}

.email-contact a:hover {
  opacity: 0.8;
}

/* Ensure all text in career-cta is visible */
.career-cta * {
  color: white;
}

@media (max-width: 768px) {
  .career-header h1 {
    font-size: 2.5rem;
    padding-top: 60px;
  }

  .company-culture h2, .benefits h2, .career-cta h2 {
    font-size: 2rem;
  }

  .culture-grid {
    grid-template-columns: 1fr;
  }

  .email-contact {
    font-size: 1.1rem;
  }
}
