.guardrails-animation {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.animation-frame {
  text-align: center;
  padding: 20px;
  animation: fadeInOut 5s infinite;
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0; }
  10%, 90% { opacity: 1; }
}

.animation-frame h3 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #003366;
}

.shield-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.shield-icon {
  font-size: 8rem;
  transition: color 0.3s ease;
}

.shield-icon.green {
  color: #006400;
}

.shield-icon.red {
  color: #d8000c;
}

@media (max-width: 768px) {
  .animation-frame h3 {
    font-size: 1.2rem;
  }

  .shield-icon {
    font-size: 6rem;
  }
}
