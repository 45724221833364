.your-own-ai-section {
  padding: 4rem 0;
  background-color: #f5f5f5;
  overflow-x: hidden;
}

.your-own-ai-header {
  text-align: left;
  margin-bottom: 3rem;
  padding-left: 70px;
}

.your-own-ai-heading {
  color: #4a4a4a;
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;
  word-wrap: break-word;
  max-width: 800px;
}

.your-own-ai-subheading {
  font-size: 1.2rem;
  color: #666;
  line-height: 1.4;
  word-wrap: break-word;
  max-width: 800px;
}

.your-own-ai-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 70px;
  padding-right: 70px;
}

.your-own-ai-content .row {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.conversation-card-column {
  display: flex;
  justify-content: flex-start;
  padding-right: 0;
}

.conversation-card-column .conversation-card {
  background-color: #ffffff;
  border: none;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
  width: 100% !important;
  height: 500px;
  position: relative;
  overflow: hidden;
  margin-left: -60px; /* Move the card 60px to the left */
}

.right-side-content-column {
  display: flex;
  justify-content: flex-start;
  padding-left: 0;
}

.right-side-content {
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.shifted-content {
  padding-left: 3rem; /* Increase spacing between content and card */
}

.your-own-ai-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.your-own-ai-item .your-own-ai-icon {
  font-size: 2rem;
  color: #0056b3;
  margin-right: 1rem;
  flex-shrink: 0;
}

.your-own-ai-item h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.your-own-ai-item p {
  font-size: 1rem;
  color: #666;
  line-height: 1.4;
}

@media (max-width: 1200px) {
  .conversation-card-column .conversation-card {
    width: 130% !important;
    margin-left: -50px; /* Adjust for smaller screens */
  }
  
  .shifted-content {
    padding-left: 2rem; /* Adjust spacing for smaller screens */
  }
}

@media (max-width: 992px) {
  .your-own-ai-header,
  .your-own-ai-content {
    padding-left: 40px;
    padding-right: 40px;
  }

  .your-own-ai-heading {
    font-size: 2.64rem;
  }

  .conversation-card-column .conversation-card {
    height: 450px;
    width: 120% !important;
    margin-left: -40px; /* Adjust for smaller screens */
  }

  .right-side-content {
    padding: 1.5rem;
  }

  .shifted-content {
    padding-left: 1.5rem; /* Adjust spacing for smaller screens */
  }

  .your-own-ai-item h3 {
    font-size: 1.3rem;
  }

  .your-own-ai-item p {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .your-own-ai-section {
    padding: 3rem 0;
  }

  .your-own-ai-header,
  .your-own-ai-content {
    padding-left: 20px;
    padding-right: 20px;
  }

  .your-own-ai-heading {
    font-size: 2.4rem;
  }

  .your-own-ai-subheading {
    font-size: 1rem;
  }

  .conversation-card-column .conversation-card {
    height: 400px;
    margin-bottom: 2rem;
    width: 100% !important;
    margin-left: 0; /* Reset margin for mobile view */
  }

  .right-side-content {
    padding: 1rem;
  }

  .shifted-content {
    padding-left: 0; /* Reset padding for mobile view */
  }

  .your-own-ai-item {
    margin-bottom: 1.5rem;
  }

  .your-own-ai-item .your-own-ai-icon {
    font-size: 1.5rem;
  }

  .your-own-ai-item h3 {
    font-size: 1.2rem;
  }

  .your-own-ai-item p {
    font-size: 0.9rem;
  }
}
