.cookie-preferences {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem 1rem;
  padding-top: 80px;
}

.cookie-preferences h1 {
  color: #0A369D;
  margin-bottom: 1rem;
}

.cookie-preferences p {
  margin-bottom: 2rem;
}

.cookie-preferences .form-group {
  margin-bottom: 1.5rem;
}

.cookie-preferences .form-check {
  padding-left: 2rem;
}

.cookie-preferences .form-check-input {
  margin-left: -2rem;
}

.cookie-preferences .form-text {
  margin-left: 2rem;
  font-size: 0.9rem;
}

.cookie-preferences button {
  margin-top: 1rem;
}
