/* Styles for ForgotPassword component */
.forgot-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 10rem);
  padding: 5rem 1rem;
}

.forgot-password-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 31.25rem;
  padding: 2rem;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 0.75rem;
  box-shadow: 0 0.375rem 0.5rem rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.forgot-password-form:hover {
  box-shadow: 0 0.625rem 0.9375rem rgba(0, 0, 0, 0.15);
}

.forgot-password-heading {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1.5rem;
}

.forgot-password-submit-btn {
  width: 100%;
  margin-bottom: 1rem;
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
  border-radius: 2rem;
  background-color: #2a65d3;
  color: #ffffff;
  border: none;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
  cursor: pointer;
}

.forgot-password-submit-btn:hover {
  background-color: #276bd1;
  transform: translateY(-2px);
}

.forgot-password-submit-btn:active {
  transform: translateY(0);
}

.forgot-password-login-link {
  text-align: center;
  width: 100%;
  margin-top: 1.5rem;
  font-size: 0.9rem;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.fade-out {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 1s ease-out, max-height 1s ease-out;
}

.fade-in {
  opacity: 1;
  max-height: 6.25rem;
  transition: opacity 1s ease-in, max-height 1s ease-in;
}

.red-timer {
  color: red;
}

.blinking-timer {
  animation: blink 1s linear infinite;
}

.otp-input {
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  font-size: 1.25rem;
  margin: 0 0.25rem;
  border: 1px solid #e0e0e0;
  border-radius: 0.25rem;
}

.resend-button {
  opacity: 1;
  cursor: pointer;
  pointer-events: auto;
  background: none;
  border: none;
  color: #2a65d3;
  font-size: 0.9rem;
  text-decoration: underline;
  margin-top: 0.5rem;
}

.resend-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.login-link {
  text-decoration: none;
  color: #2a65d3;
}

.forgot-password-login-link a {
  text-decoration: none;
  color: #2a65d3;
}

.forgot-password-login-link a:hover {
  text-decoration: none;
}

@media screen and (max-width: 480px) {
  .forgot-password-form {
    padding: 1.5rem;
  }

  .forgot-password-heading {
    font-size: 1.75rem;
  }

  .forgot-password-submit-btn,
  .otp-input {
    font-size: 0.9rem;
  }

  .otp-input {
    width: 2rem;
    height: 2rem;
  }
}
