/* Styles for SignupThree component */
.signup-three-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 160px); /* Adjust for both header and footer height */
  padding: 80px 0; /* Add padding top and bottom */
}

.signup-three-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  padding: 3rem;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  position: relative;
}

.signup-three-skip-container {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.signup-three-form:hover {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
}

.signup-three-heading {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 1rem;
}

.signup-three-subheading {
  font-size: 1.1rem;
  text-align: center;
  margin-bottom: 2rem;
}

.signup-three-input {
  width: 100%;
  margin-bottom: 1.5rem;
}

.signup-three-input input,
.signup-three-input select {
  font-size: 1.1rem;
  padding: 0.75rem;
  width: 100%;
}

.signup-three-submit-btn {
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 2rem;
  background-color: #2a65d3;
  color: #ffffff;
  border: none;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
  cursor: pointer;
}

.signup-three-submit-btn:hover {
  background-color: #276bd1;
  transform: translateY(-2px);
}

.signup-three-submit-btn:active {
  transform: translateY(0);
}

.signup-three-skip-link {
  text-decoration: none;
  cursor: pointer;
  color: #000000;
  font-size: 1rem;
}

.signup-three-skip-link:hover {
  text-decoration: none;
}

.signup-three-login-link {
  text-decoration: none;
  color: #000000;
  font-size: 1.1rem;
  text-align: center;
  width: 100%;
  margin-top: 1.5rem;
}

.signup-three-login-link a {
  font-weight: bold;
  color: #007bff;
  text-decoration: none;
}

.signup-three-login-link a:hover {
  text-decoration: none;
}

/* Custom styles for the dropdown */
.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
  padding-right: 2.5rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  font-size: 1.1rem;
  line-height: 1.5;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  max-height: 200px;
  overflow-y: auto;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-select:hover {
  border-color: #b3d7ff;
}

.custom-select option {
  padding: 0.5rem;
  cursor: pointer;
}

.custom-select option:hover,
.custom-select option:focus {
  background-color: #f8f9fa;
}

/* Styling for webkit browsers */
.custom-select::-webkit-scrollbar {
  width: 8px;
}

.custom-select::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-select::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.custom-select::-webkit-scrollbar-thumb:hover {
  background: #555;
}
