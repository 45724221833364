@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

.ai-talker-section {
  padding: 4rem 0;
  background-color: #ecf3fa;
}

.ai-talker-section h2 {
  color: #333;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.ai-talker-section .subtitle {
  font-family: 'Roboto', sans-serif;
  font-size: 1.9rem;
  font-weight: 300;
  color: #2c3e50;
  max-width: 1000px;
  margin: 0 auto 3rem;
  line-height: 1.4;
  letter-spacing: 0.5px;
}

.highlight-text {
  font-weight: 700;
}

.ai-talker-highlight {
  color: #0056b3;
  font-weight: bold;
}

.animated-text-container {
  display: inline-block;
  position: relative;
  height: 1.5em;
  margin-left: 0.2em;
  vertical-align: top;
  width: auto;
  min-width: 300px;
  overflow: visible;
}

.animated-text {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transform: translateY(100%);
  transition: all 0.5s ease;
  width: 100%;
  text-align: left;
  white-space: nowrap;
  color: #0056b3;
}

.animated-text.active {
  opacity: 1;
  transform: translateY(0);
}

.ai-talker-card {
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  height: 100%;
  background-color: #ffffff;
}

.ai-talker-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.ai-talker-card .card-body {
  padding: 2rem;
}

.ai-talker-card .card-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 0;
}

.feature-icon {
  font-size: 2.5rem;
  color: #0056b3;
  margin-right: 1rem;
}

.feature-icon-small {
  font-size: 1.2rem;
  color: #0056b3;
  margin-right: 0.5rem;
}

.ai-talker-card p {
  font-size: 1.2rem;
  color: #555;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

/* Phone Call Card Styles */
.ai-talker-card.phone-call .card-body {
  padding: 2rem;
}

.phone-call-image {
  max-width: 100%;
  height: auto;
}

.content-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.feature-list-container {
  margin-top: auto;
}

.feature-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.feature-list-item {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 1rem;
  width: 50%;
  padding-right: 1rem;
}

.feature-list-item .feature-icon-small {
  margin-right: 0.5rem;
}

@media (max-width: 992px) {
  .ai-talker-section h2 {
    font-size: 2.2rem;
  }

  .ai-talker-section .subtitle {
    font-size: 1.7rem;
  }

  .animated-text-container {
    min-width: 250px;
  }

  .ai-talker-card {
    margin-bottom: 2rem;
  }

  .feature-list-item {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .ai-talker-section {
    padding: 3rem 0;
  }

  .ai-talker-section .subtitle {
    font-size: 1.5rem;
    line-height: 1.6;
  }

  .animated-text-container {
    min-width: 200px;
    height: 1.5em;
    display: inline-flex;
    align-items: center;
  }

  .animated-text {
    position: static;
    transform: none;
    white-space: normal;
    text-align: center;
  }

  .ai-talker-card .card-body {
    padding: 1.5rem;
  }

  .feature-icon {
    font-size: 2rem;
  }

  .ai-talker-card .card-title {
    font-size: 1.5rem;
  }

  .ai-talker-card p {
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
  }

  .feature-list-item {
    font-size: 1rem;
    margin-bottom: 0.75rem;
  }

  .phone-call-image {
    max-width: 200px;
    margin: 0 auto 1.5rem;
  }
}

@media (max-width: 480px) {
  .ai-talker-section h2 {
    font-size: 1.8rem;
  }

  .ai-talker-section .subtitle {
    font-size: 1.2rem;
    line-height: 1.5;
  }

  .animated-text-container {
    min-width: 150px;
    height: 2.5em;
    margin-left: 0;
    display: inline-block;
  }

  .animated-text {
    font-size: 1.2rem;
    white-space: normal;
    text-align: center;
  }

  .ai-talker-card .card-title {
    font-size: 1.3rem;
  }

  .ai-talker-card p {
    font-size: 1rem;
  }

  .feature-list-item {
    font-size: 0.9rem;
  }
}
