.ai-talker-animation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 85%;
  width: 80%; /* Added to reduce width by 20% */
  padding: 1.7rem;
  text-align: center;
  font-size: 0.85em;
  margin: 0 auto; /* Center the card horizontally */
}

.ai-talker-animation h3 {
  font-size: 1.275rem;
  color: #333;
  margin-bottom: 1.7rem;
  transition: opacity 0.3s ease-in-out;
}

.content-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 255px;
}

.waving-container {
  position: relative;
  width: 136px; /* Further reduced from 170px */
  height: 136px; /* Further reduced from 170px */
}

.ai-icon {
  font-size: 81.6px; /* Further reduced from 102px */
  position: absolute;
  top: 0;
  left: 0;
  width: 136px; /* Further reduced from 170px */
  height: 136px; /* Further reduced from 170px */
  display: flex;
  justify-content: center;
  align-items: center;
}

.ai-icon.waving {
  animation: wave 0.67s infinite;
}

.typing-container {
  height: 136px; /* Further reduced from 170px */
  display: flex;
  justify-content: center;
  align-items: center;
}

.typed-name {
  font-size: 2.04rem; /* Further reduced from 2.55rem */
  color: #6200ea;
  font-weight: bold;
}

.voice-container {
  height: 136px; /* Further reduced from 170px */
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.voice-option {
  position: absolute;
  left: 0;
  right: 0;
  font-size: 1.02rem; /* Further reduced from 1.275rem */
  color: #333;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  text-align: center;
  pointer-events: none;
}

.voice-option.visible {
  opacity: 1;
}

.voice-option.active {
  color: #6200ea;
  font-weight: bold;
  font-size: 1.36rem; /* Further reduced from 1.7rem */
}

.ready-container {
  height: 136px; /* Further reduced from 170px */
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.big-confetti {
  font-size: 4.08rem; /* Further reduced from 5.1rem */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  animation: pulse 0.67s infinite alternate;
}

.confetti-particle {
  position: absolute;
  font-size: 0.34rem; /* Further reduced from 0.425rem */
  animation: fall linear infinite;
  z-index: 1;
}

.bottom-container {
  width: 100%;
  margin-top: 1.36rem; /* Further reduced from 1.7rem */
}

.ai-talker-animation .progress {
  width: 100%;
  margin-bottom: 0.68rem; /* Further reduced from 0.85rem */
  transition: all 0.3s ease-in-out;
}

@keyframes wave {
  0%, 100% { transform: rotate(0deg); }
  50% { transform: rotate(20deg); }
}

@keyframes pulse {
  0% { transform: translate(-50%, -50%) scale(1); }
  100% { transform: translate(-50%, -50%) scale(1.1); }
}

@keyframes fall {
  0% { transform: translateY(-100%) rotate(0deg); }
  100% { transform: translateY(100vh) rotate(720deg); }
}

@media (max-width: 768px) {
  .ai-talker-animation h3 {
    font-size: 0.884rem; /* Further reduced from 1.105rem */
  }

  .waving-container {
    width: 102px; /* Further reduced from 127.5px */
    height: 102px; /* Further reduced from 127.5px */
  }

  .ai-icon {
    font-size: 61.2px; /* Further reduced from 76.5px */
    width: 102px; /* Further reduced from 127.5px */
    height: 102px; /* Further reduced from 127.5px */
  }

  .typed-name {
    font-size: 1.7rem; /* Further reduced from 2.125rem */
  }

  .voice-container {
    height: 108.8px; /* Further reduced from 136px */
  }

  .voice-option {
    font-size: 0.816rem; /* Further reduced from 1.02rem */
  }

  .voice-option.active {
    font-size: 1.088rem; /* Further reduced from 1.36rem */
  }

  .big-confetti {
    font-size: 2.72rem; /* Further reduced from 3.4rem */
  }
}
