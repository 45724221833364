.blog-card-link {
  display: block;
  text-decoration: none;
  color: #333;
  transition: color 0.3s ease;
  margin-bottom: 1rem;
}

.blog-card-link:hover {
  text-decoration: none;
  color: #000;
}

.blog-card {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: auto;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.blog-card:hover {
  transform: scale(1.02);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  background-color: #f9fafb;
}

.blog-card-image-container {
  padding: 0.5rem;
  flex-shrink: 0;
}

.blog-card-image {
  width: 100%;
  height: 8rem;
  object-fit: cover;
  border-radius: 0.25rem;
  user-select: none;
  pointer-events: none;
}

.blog-card-content {
  flex: 1;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.blog-card-title {
  font-size: 1rem;
  font-weight: bold;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-decoration: none;
  user-select: none;
  pointer-events: none;
  color: #333;
  transition: color 0.3s ease;
  margin-bottom: 0.5rem;
}

.blog-card:hover .blog-card-title {
  color: #000;
}

.blog-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 0.5rem;
}

.blog-card-date {
  font-size: 0.75rem;
  color: #666;
  text-decoration: none;
  user-select: none;
  pointer-events: none;
}

.blog-card-read-more {
  font-size: 0.75rem;
  color: #0066cc;
  font-weight: 600;
  text-decoration: none;
  user-select: none;
  pointer-events: none;
  transition: color 0.3s ease;
}

.blog-card:hover .blog-card-read-more {
  color: #004499;
  text-decoration: underline;
}

@media (min-width: 640px) {
  .blog-card {
    flex-direction: row;
    height: 6rem;
  }

  .blog-card-image-container {
    width: 6rem;
  }

  .blog-card-image {
    width: 5rem;
    height: 5rem;
  }

  .blog-card-content {
    padding: 0.75rem 1rem;
  }

  .blog-card-title {
    font-size: 0.875rem;
    -webkit-line-clamp: 1;
    margin-bottom: 0;
  }

  .blog-card-footer {
    margin-top: 0;
  }
}

@media (min-width: 1024px) {
  .blog-card-title {
    font-size: 1rem;
  }

  .blog-card-date,
  .blog-card-read-more {
    font-size: 0.875rem;
  }
}
