.policy-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  padding-top: 80px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
}

.policy-title {
  font-size: 2.5rem;
  color: #4472ca;
  margin-bottom: 1rem;
  text-align: center;
}

.policy-date {
  font-size: 1rem;
  color: #666;
  text-align: center;
  margin-bottom: 2rem;
}

.policy-content {
  background-color: #f9f9f9;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.policy-content h2 {
  font-size: 1.8rem;
  color: #4472ca;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.policy-content h3 {
  font-size: 1.5rem;
  color: #4472ca;
  margin-top: 1.5rem;
  margin-bottom: 0.8rem;
}

.policy-content h4 {
  font-size: 1.2rem;
  color: #4472ca;
  margin-top: 1.2rem;
  margin-bottom: 0.6rem;
}

.policy-content p {
  margin-bottom: 1rem;
}

.policy-content ul {
  margin-bottom: 1rem;
  padding-left: 2rem;
}

.policy-content li {
  margin-bottom: 0.5rem;
}

.policy-content a {
  color: #4472ca;
  text-decoration: none;
}

.policy-content a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .policy-container {
    padding: 1rem;
    padding-top: 80px;
  }

  .policy-title {
    font-size: 2rem;
  }

  .policy-content {
    padding: 1.5rem;
  }
}
