@import url('https://fonts.googleapis.com/css2?family=Dosis&family=Gloria+Hallelujah&family=Inter:wght@300&family=Nothing+You+Could+Do&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Mono&family=Shadows+Into+Light&display=swap');

body, html {
  margin: 0;
  padding: 0;
  background-color: #f1f6f8;
}

#root {
  min-height: 100vh;
  background-color: #f1f6f8;
}

/* Add any other global styles here */
