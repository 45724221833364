/* BlogDetailPage styles */
.blog-detail-container {
  max-width: 50rem;
  margin: 0 auto;
  padding: 2rem 1rem;
}

.blog-detail-title {
  font-size: 2rem;
  font-weight: bold;
  color: #2d3748;
  margin-bottom: 1rem;
}

.blog-detail-meta {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  color: #718096;
}

.blog-detail-date,
.blog-detail-author {
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.blog-detail-author {
  font-weight: 500;
}

.blog-detail-image {
  width: 100%;
  max-height: 25rem;
  object-fit: cover;
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
}

.blog-detail-content {
  font-size: 1rem;
  line-height: 1.6;
  color: #4a5568;
}

.blog-detail-content p {
  margin-bottom: 1.25rem;
}

.blog-detail-content h2 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #2d3748;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.blog-detail-content h3 {
  font-size: 1.25rem;
  font-weight: bold;
  color: #2d3748;
  margin-top: 1.25rem;
  margin-bottom: 0.75rem;
}

.blog-detail-content ul,
.blog-detail-content ol {
  margin-bottom: 1.25rem;
  padding-left: 1.5rem;
}

.blog-detail-content li {
  margin-bottom: 0.5rem;
}

.blog-detail-content a {
  color: #3182ce;
  text-decoration: underline;
}

.blog-detail-content a:hover {
  color: #2c5282;
}

/* Loading spinner */
.blog-detail-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}

.blog-detail-spinner {
  animation: spin 1s linear infinite;
  border-top: 2px solid #3182ce;
  border-right: 2px solid #3182ce;
  border-bottom: 2px solid #3182ce;
  border-left: 2px solid transparent;
  border-radius: 50%;
  height: 4rem;
  width: 4rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Error message */
.blog-detail-error {
  text-align: center;
  margin-top: 2rem;
  color: #e53e3e;
  font-size: 1.125rem;
}

/* Back to Blogs link */
.blog-detail-back-link {
  color: #3182ce;
  display: inline-flex;
  align-items: center;
  margin-bottom: 1rem;
  text-decoration: none;
  font-size: 1rem;
}

.blog-detail-back-link:hover {
  color: #2c5282;
}

.blog-detail-back-icon {
  height: 1.25rem;
  width: 1.25rem;
  margin-right: 0.5rem;
}

/* Blog description */
.blog-detail-description {
  font-size: 1rem;
  line-height: 1.6;
  color: #4a5568;
  margin-bottom: 1.5rem;
}

/* Tags section */
.blog-detail-tags {
  margin-top: 1.5rem;
}

.blog-detail-tags h3 {
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.blog-detail-tags-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.blog-detail-tag {
  background-color: #e2e8f0;
  color: #4a5568;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
}

/* Back to Top button */
.blog-detail-back-to-top {
  text-align: center;
  margin-top: 2rem;
}

.blog-detail-back-to-top-button {
  background-color: #3182ce;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.blog-detail-back-to-top-button:hover {
  background-color: #2c5282;
}

@media screen and (max-width: 768px) {
  .blog-detail-container {
    padding: 1.5rem 1rem;
  }

  .blog-detail-title {
    font-size: 1.75rem;
  }

  .blog-detail-content {
    font-size: 0.9375rem;
  }

  .blog-detail-content h2 {
    font-size: 1.375rem;
  }

  .blog-detail-content h3 {
    font-size: 1.125rem;
  }
}

@media screen and (max-width: 480px) {
  .blog-detail-container {
    padding: 1rem;
  }

  .blog-detail-title {
    font-size: 1.5rem;
  }

  .blog-detail-meta {
    flex-direction: column;
    align-items: flex-start;
  }

  .blog-detail-content {
    font-size: 0.875rem;
  }

  .blog-detail-content h2 {
    font-size: 1.25rem;
  }

  .blog-detail-content h3 {
    font-size: 1rem;
  }

  .blog-detail-back-to-top-button {
    font-size: 0.875rem;
    padding: 0.375rem 0.75rem;
  }
}
