.partner-with-us-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  padding-top: 80px;
}

.partner-header {
  text-align: center;
  margin-bottom: 60px;
}

.partner-header h1 {
  font-size: 3rem;
  color: black;
  font-weight: bold;
  margin-bottom: 20px;
}

.partner-header p {
  font-size: 1.2rem;
  color: #333;
}

.partner-benefits, .partnership-types {
  margin-bottom: 60px;
}

.partner-benefits h2, .partnership-types h2 {
  font-size: 2.5rem;
  color: black;
  margin-bottom: 30px;
  text-align: center;
}

.benefits-grid, .partnership-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
}

.benefit-item, .partnership-item {
  background-color: #f8f9fa;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.benefit-item:hover, .partnership-item:hover {
  transform: translateY(-5px);
}

.benefit-item h3, .partnership-item h3 {
  font-size: 1.5rem;
  color: black;
  margin-bottom: 15px;
}

.benefit-item p, .partnership-item p {
  font-size: 1rem;
  color: #555;
}

.partner-cta {
  text-align: center;
  background-color: #0A369D;
  color: white;
  padding: 60px 20px;
  border-radius: 10px;
}

.partner-cta h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: white; /* Ensure heading is white */
}

.partner-cta p {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: white; /* Ensure paragraph text is white */
}

.partner-email {
  font-size: 2rem;
  margin-top: 30px;
}

.partner-email a {
  color: #ffffff;
  text-decoration: none;
  transition: opacity 0.3s ease;
}

.partner-email a:hover {
  opacity: 0.8;
  text-decoration: underline;
}

/* Ensure all text in partner-cta is visible */
.partner-cta * {
  color: white;
}

@media (max-width: 768px) {
  .partner-header h1 {
    font-size: 2.5rem;
  }

  .partner-benefits h2, .partnership-types h2, .partner-cta h2 {
    font-size: 2rem;
  }

  .benefits-grid, .partnership-grid {
    grid-template-columns: 1fr;
  }

  .partner-email {
    font-size: 1.5rem;
  }
}
