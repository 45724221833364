@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

.industry-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  padding-top: 100px;
  font-family: 'Poppins', sans-serif;
  justify-content: center;
  text-align: left;
}

.industry-container h1 {
  font-size: 3rem;
  color: black;
  font-weight: bold;
  margin-bottom: 20px;
  font-weight: 600;
  justify-content: center;
  animation: fadeInDown 1s ease-out;
  margin-top: 20px;
}

.industry-container p {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 20px;
  animation: fadeIn 1s ease-out;
}

.features, .benefits, .industries-list {
  margin-bottom: 40px;
  
  animation: fadeInUp 1s ease-out;
}

.features h2, .benefits h2, .industries-list h2 {
  font-size: 2.2rem;
  color: black;
  margin-bottom:50px;
  font-weight: 600;
}

.features ul, .benefits ul, .industries-list ul {
  list-style-type: none;
  padding: 0;
}

.features li, .benefits li, .industries-list li {
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
}

.features h3 {
  font-size: 1.5rem;
  color: black;
  margin-bottom: 10px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.icon {
  font-size: 1.5rem;
  margin-right: 15px;
  color: black;
  flex-shrink: 0;
}

.feature-content {
  display: flex;
  flex-direction: column;
}

.features li > .icon {
  margin-right: 15px;
  align-self: flex-start;
}

.features h3 .icon {
  display: none;
}

.cta {
  background-color: #f8f9fa;
  padding: 40px;
  border-radius: 10px;
  background: #0A369D;
  text-align: center;
  animation: fadeIn 1s ease-out;
}

.cta h2 {
  font-size: 2.2rem;
  color: white;
  margin-bottom: 20px;
  font-weight: 600;
}
.cta p{
  color: white;
}
.cta-button {
  background-color: #2a64ec;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 1.1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
}

.cta-button:hover {
  background-color: #1651da;
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .industry-container h1 {
    font-size: 2.5rem;
    padding-top: 80px;
  }

  .features h2, .benefits h2, .industries-list h2, .cta h2 {
    font-size: 2rem;
  }

  .features h3 {
    font-size: 1.3rem;
  }
}
